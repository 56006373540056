'use client';
import { AutoComplete, Button, Flex, Form, Input } from 'antd';
import cn from 'classnames';
import Image from 'next/image';
import RouterLink from 'next/link';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import SearchIcon from '@/app/_icons/search.svg';
import { FilesMeta } from '@/app/_types/file';
import { Header as HeaderType } from '@/app/_types/header';
import { SearchResultsPage } from '@/app/_types/search-results-page';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import ButtonCustom from '../button/ButtonCustom';

import { Hamburger } from './hamburger/Hamburger';
import Styles from './Header.module.scss';
import DesktopMenu from './megamenu/DesktopMenu';
import MegaMenu from './megamenu/MegaMenu';

type HeaderProps = HeaderType & {
  searchResultsPage?: SearchResultsPage | null;
  filesMeta?: FilesMeta;
};

const Header = (props: HeaderProps) => {
  const { filesMeta = {} } = props;
  const [isMenuOpen, setIsMenuOpen] = useState<string | null>(null);
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [searchSuggestionOptions, setSearchSuggestionOptions] = useState<
    { value: string }[]
  >([]);

  const router = useRouter();
  const path = usePathname();
  const searchBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuVisibleChange = (key: string, visible: boolean) => {
    if (visible) {
      setIsMenuOpen(key);
    } else {
      setIsMenuOpen(null);
    }
  };

  const goToSearchResults = (search: string) => {
    const newHref = `${props.search_results_nav.permalink}?q=${encodeURIComponent(search)}`;

    if (path.indexOf(props.search_results_nav.permalink) === 0) {
      // if we're already on the search results page, force a full page refresh
      window.location.href = newHref;
    } else {
      // if we're on any other page, navigate to the search results page
      router.push(newHref);
    }
  };

  const handleSearchRedirect = useCallback(() => {
    if (searchTerm.length) {
      goToSearchResults(searchTerm);
    }
  }, [searchTerm]);

  const toggleSearch = useCallback(() => {
    setIsSearchOpen(!isSearchOpen);
    if (isSearchOpen === true) {
      handleSearchRedirect();
    }
  }, [isSearchOpen, handleSearchRedirect]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    if (props.searchResultsPage?.suggestions) {
      const filteredSuggestions = props.searchResultsPage?.suggestions
        .filter((suggestion) =>
          suggestion.toLowerCase().includes(value.toLowerCase()),
        )
        .map((suggestion) => ({ value: suggestion }));

      setSearchSuggestionOptions(filteredSuggestions);
    }
  };

  const handleSuggestionSelect = (value: string) => {
    setSearchTerm(value);
    goToSearchResults(value);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target as Node)
      ) {
        setIsSearchOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className={cn(Styles.headerWrapper, { [Styles.fixed]: isFixed })}>
      <div className={Styles.topNav}>
        <div className="container">
          <Flex align="center" justify="space-between" gap={40}>
            <ul className={Styles.contactList}>
              <li>
                {props.assist_title}{' '}
                <a href={`tel:${props.assist_number}`}>{props.assist_number}</a>
              </li>
              <li>
                {props.claims_title}{' '}
                <a href={`tel:${props.claims_number}`}>{props.claims_number}</a>
              </li>
            </ul>
            <ul className={Styles.actionList}>
              {props.buttons?.map((itemM2A, index) => {
                const item = itemM2A.item;
                const isSecondary = index % 2;

                return (
                  <li key={itemM2A.id}>
                    <ButtonCustom
                      className={cn({ btnPrimaryAltOutline: isSecondary })}
                      href={item.url || ''}
                      btnText={item.text}
                      fitWidth={true}
                      extraClass={'text-nowrap'}
                    />
                  </li>
                );
              })}
            </ul>
          </Flex>
        </div>
      </div>
      <div className={Styles.mainNav}>
        <div className="container pos-relative">
          <Flex align="center" justify="space-between">
            <RouterLink
              href="/"
              data-cy="header-logo-link"
              className={Styles.logoLink}
            >
              <Image
                src={getDirectusAssetURI(props.main_logo) || ''}
                alt={getAltProperty(filesMeta, props.main_logo, 'Bryte Logo')}
                className={Styles.brandLogo}
                width={142}
                height={56}
                priority
              />
            </RouterLink>

            <div className={Styles.desktopNavigation}>
              <ul className={Styles.menuNavigation}>
                {props.navigation &&
                  props.navigation.map((navM2M) => {
                    const { Title, navigation, children, new_tab } =
                      navM2M.navigation_items_id;
                    const menuKey = String(navM2M.id);
                    const href = navigation.permalink || '';

                    return (
                      <li
                        className={cn(Styles.menuItem, Styles.megaMenu)}
                        key={menuKey}
                      >
                        {children && children.length > 0 ? (
                          <MegaMenu
                            content={
                              <DesktopMenu
                                items={children}
                                staticItems={props.static_navigation_links}
                              />
                            }
                            href={href}
                            title={Title}
                            newTab={new_tab ?? false}
                            onVisibleChange={(visible: boolean) =>
                              handleMenuVisibleChange(menuKey, visible)
                            }
                            visible={isMenuOpen === menuKey}
                          />
                        ) : (
                          <Link
                            href={href}
                            className={Styles.menuLink}
                            target={new_tab ? '_blank' : '_self'}
                          >
                            {Title}
                          </Link>
                        )}
                      </li>
                    );
                  })}

                <li className={Styles.menuItem}>
                  <Button
                    type="text"
                    className={Styles.searchBtn}
                    onClick={toggleSearch}
                  >
                    <Image
                      src={SearchIcon}
                      alt="Search"
                      height={18}
                      width={18}
                    />
                  </Button>
                  <div
                    ref={searchBoxRef}
                    className={cn(
                      Styles.searchBox,
                      isSearchOpen && Styles.searchOpen,
                    )}
                  >
                    <Form onFinish={toggleSearch} className={Styles.searchForm}>
                      <AutoComplete
                        options={searchSuggestionOptions}
                        onSearch={handleSearch}
                        onSelect={handleSuggestionSelect}
                      >
                        <Input
                          name="search_term"
                          placeholder="Search..."
                          className={cn(Styles.searchBoxInput, 'customInput')}
                        />
                      </AutoComplete>
                      <Button
                        className={Styles.searchBtn}
                        onClick={toggleSearch}
                      >
                        <Image
                          src={SearchIcon}
                          alt="Search"
                          height={14}
                          width={14}
                        />
                      </Button>
                    </Form>
                  </div>
                </li>
              </ul>
            </div>

            <div className={Styles.mobileNavigation}>
              <Hamburger
                {...props}
                searchSuggestionOptions={searchSuggestionOptions}
                onSearch={handleSearch}
                onSuggestionSelect={handleSuggestionSelect}
                onSearchRedirect={handleSearchRedirect}
              />
            </div>
          </Flex>
        </div>
      </div>
    </header>
  );
};

export default Header;
